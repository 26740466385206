import styles from "./ToastLinkCopied.module.scss";
import { useIntl } from "react-intl";
import ok from "../../assets/img/svg/okGreen.svg";

export const ToastLinkCopied = () => {
	const intl = useIntl();

	return (
		<div className={styles.content}>
			<div>{intl.formatMessage({ id: "Link copied to your clipboard" })}</div>
			<img src={ok} alt="" />
		</div>
	);
};
