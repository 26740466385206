import React from "react";
import styles from "./CommentingBlock.module.scss";
import cl from "classnames";

interface ICommentingBlockProps {
	children: React.ReactNode | any;
	type: "error" | "done" | "waiting";
	className?: string;
}

export const CommentingBlock: React.FC<ICommentingBlockProps> = ({
	children,
	type,
	className,
}) => {
	return (
		<div className={cl(styles.block, styles[type], className)}>
			<div>{children}</div>
		</div>
	);
};
