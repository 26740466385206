import styles from "./SocialNetworks.module.scss";
import discord from "../../assets/img/svg/discord.svg";
import twitch from "../../assets/img/svg/twitch.svg";
import google from "../../assets/img/svg/google.svg";
import fb from "../../assets/img/svg/fb.svg";
import cl from "classnames";

export const SocialNetworks = () => {
	return (
		<div className={cl(styles.content, styles.disabled)}>
            <img src={google} alt="" />
            <img src={fb} alt="" />
			<img src={discord} alt="" />
            <img src={twitch} alt="" />
		</div>
	);
};
