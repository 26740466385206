import { useContext, useEffect, useState } from "react";
import styles from "./AppsPage.module.scss";
import { Context } from "src";
import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";
import { AppItem } from "src/components/AppItem/AppItem";
import comigSoon from "../../assets/img/svg/comigSoon.svg";
import cl from "classnames";
import categoriesImage from "../../assets/img/svg/categories.svg";
import gamepad from "../../assets/img/svg/gamepad.svg";
import utility from "../../assets/img/svg/utility.svg";
import other from "../../assets/img/svg/other.svg";

export const AppsPage = observer(() => {
	const { storeUser } = useContext(Context);
	const intl = useIntl();
	const [isOpenCategory, setIsOpenCategory] = useState(false);
	const [currentCategory, setCurrentCategory] = useState<{
		name: string;
		image: string;
	}>({ name: "All Apps", image: categoriesImage });
	const categories = [
		{ name: "All Apps", image: categoriesImage },
		{ name: "Game", image: gamepad },
		//{ name: "Utility", image: utility },
		{ name: "Office", image: other },  
		//{ name: "Other", image: other },
	];

	useEffect(() => {
		storeUser.getApps();
	}, []);

	const arrow = (
		<svg
			width="14"
			height="9"
			viewBox="0 0 14 9"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M13 1L7 7L1 1" stroke="white" stroke-width="2" />
		</svg>
	);

	return (
		<div className={styles.content}>
			<div className={styles.title}>
				{intl.formatMessage({ id: "Apps on NEOBOX" })}
			</div>
			<div className={styles.description}>
				{intl.formatMessage({
					id: "Run apps on NEOBOX seamlessly. Choose one from the library or upload your own app and",
				})}{" "}
				<span>{intl.formatMessage({ id: "become a contributor" })}</span>.
			</div>
			<div className={styles.wrapperItems}>
				<div className={styles.menu}>
					<div className={styles.select}>
						<div
							className={styles.selectBc}
							onClick={() => {
								setIsOpenCategory(!isOpenCategory);
							}}
						>
							<div className={styles.selectBcValue} style={{ width: "124px" }}>
								<img src={currentCategory.image} alt="" />
								<div>{intl.formatMessage({ id: currentCategory.name })}</div>
							</div>
							<div
								className={cl(styles.selectBcArrow, {
									[styles.active]: isOpenCategory,
								})}
							>
								{arrow}
							</div>
						</div>
						{isOpenCategory && (
							<div className={styles.optionsSelectBc}>
								{categories.map((option) => {
									return (
										<div
											className={styles.optionSelectBc}
											onClick={() => {
												setCurrentCategory(option);
												setIsOpenCategory(!isOpenCategory);
												console.log("option", option);
											}}
										>
											<img src={option.image} alt="" />
											<div>{intl.formatMessage({ id: option.name })}</div>
										</div>
									);
								})}
							</div>
						)}
					</div>
					<div className={styles.count}>
						{
							storeUser.apps.filter(
								(i) =>
									i.category === currentCategory.name ||
									currentCategory.name === "All Apps"
							).length
						}{" "}
						{storeUser.apps.filter(
							(i) =>
								i.category === currentCategory.name ||
								currentCategory.name === "All Apps"
						).length > 1
							? intl.formatMessage({ id: "apps available" })
							: intl.formatMessage({ id: "app available" })}
					</div>
				</div>
				<div className={styles.items}>
					{storeUser.apps
						.filter(
							(i) =>
								i.category === currentCategory.name ||
								currentCategory.name === "All Apps"
						)
						.map((item, index) => {
							return <AppItem app={item} />;
						})}
					{storeUser.apps.length < 8 &&
						Array(
							8 -
								storeUser.apps.filter(
									(i) =>
										i.category === currentCategory.name ||
										currentCategory.name === "All Apps"
								).length
						)
							.fill(0)
							.map((item, index) => {
								return (
									<div className={styles.item}>
										<img src={comigSoon} alt="" />
										<div className={styles.mainContent}>
											<div>{intl.formatMessage({ id: "Coming Soon" })}</div>
										</div>
									</div>
								);
							})}
				</div>
			</div>
		</div>
	);
});
