import { IAuth } from "src/types/user";
import velas from "src/assets/img/blockchains/velas.svg";
import oasis from "src/assets/img/blockchains/oasis.svg";
import skale from "src/assets/img/blockchains/skale.svg";
import avax from "src/assets/img/blockchains/avax.svg";
import coredao from "src/assets/img/blockchains/core.svg";
import bnb from "src/assets/img/blockchains/opBnb.svg";
import pixudi from "src/assets/img/blockchains/pixudi.svg";
import arthera from "src/assets/img/blockchains/arthera.svg";
import base from "src/assets/img/blockchains/base.png";
import neox from "src/assets/img/blockchains/neox.png";

export const getBcInfo = (item, address) => {
	switch (item) {
		case IAuth.AuthTypes.velas:
			return {
				icon: velas,
				title: "Velas",
			};
		case IAuth.AuthTypes.oasis:
			return {
				icon: oasis,
				title: "Oasis",
			};
		case IAuth.AuthTypes.skale:
			return {
				icon: skale,
				title: "Skale",
			};
		case IAuth.AuthTypes.avax:
			return {
				icon: avax,
				title: "Avax",
			};
		case IAuth.AuthTypes.coredao:
			return {
				icon: coredao,
				title: "Core",
			};
		case IAuth.AuthTypes.arthera:
			return {
				icon: arthera,
				title: "Arthera",
			};
		case IAuth.AuthTypes.bnb:
			return {
				icon: bnb,
				title: "Bnb",
			};
		case IAuth.AuthTypes.pixudi:
			return {
				icon: pixudi,
				title: "Pixudi",
			};
		case IAuth.AuthTypes.base:
			return {
				icon: base,
				title: "Base",
			};
		case IAuth.AuthTypes.neox:
			return {
				icon: neox,
				title: "Neo X",
			};
	}
};
