export interface IProfile {
	container: string;
	createdAt: string;
	id: number;
	memory: string;
	nonce: string;
	username: string;
	email: string;
	email_confirmed: string;
	code: string;
	discord: string;
	password: string;
	additional_memory: string;
}

export interface IFile {
	container: string;
	id: number;
	object: string;
}

export interface ISignedData {
	publicKey: string;
	data: string;
	salt: string;
	message: string;
}

export interface IObject {
	address: {
		containerId: string;
		objectId: string;
	};
	name: string;
}

export type TLanguage = "en" | "ja" | "ch";

export interface IApp {
	category: string;
	description: string;
	id: number;
	image: string;
	link: string;
	name: string;
}

export namespace IAuth {
	export enum AuthTypes {
		near = "near",
		avax = "avax",
		ever = "ever",
		skale = "skale",
		velas = "velas",
		eth = "eth",
		email = "email",
		nickname = "nickname",
		google = "google",
		default = "",
		solana = "solana",
		moonbeam = "moonbeam",
		oasis = "oasis",
		ronin = "ronin",
		discord = "discord",
		doge = "doge",
		twitch = "twitch",
		tezos = "tezos",
		secret = "secret",
		neo = "neo",
		polygon = "polygon",
		immutable = "immutable",
		coredao = "coredao",
		arthera = "arthera",
		bnb = "bnb",
		pixudi = "pixudi",
		evm = "evm",
		base = "base",
		neox = "neox",
	}

	export interface Tokens {
		accessToken: string;
		refreshToken: string;
		errorMessage: string;
	}

	// /auth/login
	export interface Login {
		accountId: string;
	}

	export type LoginResponseSuccess = Tokens;
}
