import { toast } from "react-toastify";
import styles from "./ConfirmUpload.module.scss";
import { fetchUpload } from "src/app/api/app";
import { useContext } from "react";
import { Context } from "src";
import { modal } from "../Modal/ModalConnector";
import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";
import { IFile, IProfile } from "src/types/user";
import files from "../../assets/img/svg/files.svg";
import { modalUploading } from "../ModalUploading";
import { UploadFile } from "../UploadFile/UploadFile";

interface IConfirmUpload {
	file: File;
	image: string;
}

export const ConfirmUpload: React.FC<IConfirmUpload> = observer(
	({ file, image }) => {
		const { storeUser } = useContext(Context);
		const intl = useIntl();

		const uploadFile = async () => {
			try {
				modal.close();
				modalUploading.open(
					<UploadFile
						name={file.name}
						size={(file.size / 10 ** 6).toFixed(2)}
						mode={"uploading"}
					/>,
					{ abilityHide: true, className: "none" }
				);
				const res = await fetchUpload(image, file.name);

				if (res.data.error) {
					toast.error(String(res.data.error));
				} else {
					storeUser.setFilesLength(storeUser.files.length);
					let user: IProfile = JSON.parse(JSON.stringify(storeUser.user));
					user.memory = String(Number(user.memory) + file.size);
					storeUser.setUser(user);
					//storeUser.setNewFiles({ image: image, name: file.name });
					modal.close();
					modalUploading.open(
						<UploadFile
							name={file.name}
							size={(file.size / 10 ** 6).toFixed(2)}
							mode={"uploading"}
						/>,
						{ abilityHide: true, className: "none" }
					);
					if (storeUser.files.length === storeUser.filesLength) {
						setTimeout(async () => {
							await storeUser.setProfileData();
							modalUploading.open(
								<UploadFile
									name={file.name}
									size={(file.size / 10 ** 6).toFixed(2)}
									mode={"uploaded"}
								/>,
								{ abilityHide: true, className: "none" }
							);
							setTimeout(() => {
								modalUploading.close();
							}, 4000);
						}, 10000);
					}
					// let currentFilesBuf = currentFiles;
					// currentFilesBuf.push(fileImage);
					// setCurrentFiles([...currentFilesBuf]);
					//let files: IFile[] = JSON.parse(JSON.stringify(storeUser.user.files))
					// files.push({
					// 	container: storeUser.user.profile.container,
					// 	id: storeUser.user.files[files.length - 1].id + 1,
					// 	object: ""
					// })
				}
			} catch (e) {
				toast.error(intl.formatMessage({ id: "Memory limit!" }));
				modal.close();
				modalUploading.close();
				console.log(e);
			}
		};

		return (
			<div className={styles.content}>
				<div className={styles.title}>
					{intl.formatMessage({ id: "Confirm upload" })}
				</div>
				<img
					src={
						image.toLowerCase().includes("image/jpeg") ||
						image.toLowerCase().includes("image/png") ||
						image.toLowerCase().includes("image/svg") ||
						image.toLowerCase().includes("image/jpg") ||
						image.toLowerCase().includes("image/ico") ||
						image.toLowerCase().includes("image/eps") ||
						image.toLowerCase().includes("image/bmp") ||
						image.toLowerCase().includes("image/tiff") ||
						image.toLowerCase().includes("image/webp")
							? image
							: files
					}
					alt=""
					className={styles.image}
				/>
				<div className={styles.name}>{file.name}</div>
				<div className={styles.size}>{(file.size / 10 ** 6).toFixed(2)} MB</div>
				<div className={styles.btn} onClick={() => uploadFile()}>
					{intl.formatMessage({ id: "Upload File" })}
				</div>
			</div>
		);
	}
);
