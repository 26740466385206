import { makeAutoObservable, runInAction, toJS } from "mobx";
import {
	fetchAuthLogin,
	fetchAuthLoginMetaMask,
	fetchAuthLoginNeo,
	fetchAuthLogout,
	fetchAuthRefresh,
	fetchDelete,
	fetchGetApps,
	fetchGetNonce,
	fetchGetObjects,
	fetchGetProfile,
} from "src/app/api/app";
import {
	IApp,
	IAuth,
	IFile,
	IObject,
	IProfile,
	ISignedData,
	TLanguage,
} from "src/types/user";
import { jwtDecode } from "jwt-decode";
import { modal } from "src/components/Modal/ModalConnector";
import { digestMessage } from "src/utils/hashing";
import { getEthereumAccount } from "src/utils/crypto/metamask/metamask";

export default class StoreUser {
	user: IProfile = {
		container: "",
		createdAt: "",
		id: 0,
		memory: "",
		nonce: "",
		username: "",
		email: "",
		email_confirmed: "",
		code: "",
		discord: "",
		password: "",
		additional_memory: ""
	};
	isAuth: boolean = false;
	isLoading: boolean = true;
	timerToRefreshToken: any = null;
	isLoadingProfile: boolean = false;
	wallet: string = "";
	email: string = "";
	language: TLanguage;
	files: IFile[] = [];
	newFiles: { image: string; name: string }[] = [];
	objects: IObject[] = [];
	apps: IApp[] = [];
	filesLength: number = 0;
	typeAuthorization: string = "";
	count: number = 5;

	constructor() {
		makeAutoObservable(this);

		const lang =
			typeof window !== "undefined" ? navigator.language.slice(0, 2) : "en";

		if (localStorage.getItem("language")) {
			this.language = localStorage.getItem("language") as TLanguage;
		} else {
			switch (lang) {
				case "en":
					this.language = "en";
					break;
				case "ja":
					this.language = "ja";
					break;
				case "zh":
					this.language = "ch";
					break;
				default:
					this.language = "en";
			}
		}
	}

	changeLanguage = (language: TLanguage) => {
		console.log("language", language);
		runInAction(() => {
			this.language = language;
		});
		localStorage.setItem("language", language);
	};

	setLoading = (bool: boolean) => {
		this.isLoading = bool;
	};

	setAuth = (bool: boolean) => {
		this.isAuth = bool;
		console.log("isAuth", this.isAuth);
	};

	setUser = (user: IProfile) => {
		runInAction(() => {
			this.user = user;
		});
	};

	setFiles = (files: IFile[]) => {
		runInAction(() => {
			this.files = JSON.parse(JSON.stringify(toJS(files)));
		});
	};

	setFilesLength = (filesLength: number) => {
		runInAction(() => {
			this.filesLength = filesLength;
		});
	};

	setNewFiles = (file: { image: string; name: string }) => {
		runInAction(() => {
			this.newFiles.push(file);
		});
	};

	setApps = (apps: IApp[]) => {
		runInAction(() => {
			this.apps = apps;
		});
	};

	setTypeAuthorization = (typeAuthorization: string) => {
		runInAction(() => {
			this.typeAuthorization = typeAuthorization;
		});
	};

	setProfileData = async () => {
		this.setObjects();
		runInAction(() => {
			this.isLoadingProfile = false;
		});
		runInAction(() => {
			this.newFiles = [];
		});
		this.setProfile();
		runInAction(() => {
			this.isLoadingProfile = true;
		});
	};

	setProfile = async () => {
		try {
			const res = await fetchGetProfile();
			console.log("fetchGetProfile", res.data);
			this.setUser(res.data.profile);
			this.setFiles(res.data.files);
			//if (res.data.profile.container === null && this.count > 0) {
			//this.count -= 1;
			//setTimeout(() => {
			//this.setProfileData();
			//}, 5000);
			//}
		} catch (e) {
			console.log(e);
		}
	};

	setObjects = async () => {
		try {
			const res = await fetchGetObjects();
			if (res.data.objects) {
				runInAction(() => {
					this.objects = res.data.objects;
				});
			}
		} catch (e) {
			console.log(e);
		}
	};

	getApps = async () => {
		try {
			const res = await fetchGetApps();
			if (res.data.apps) {
				this.setApps(res.data.apps);
			}
		} catch (e) {
			console.log(e);
		}
	};

	deleteFile = async (object: string) => {
		try {
			const res = await fetchDelete(object);
			if (res.data) {
				modal.close();
				await this.setProfile();
				return true;
			}
		} catch (e) {
			console.log(e);
		}
	};

	loginEmail = async (type: string, idUser: string, password?: string) => {
		if (password) {
			password = await digestMessage(password);
		}
		try {
			let serverResponse;
			serverResponse = await fetchAuthLogin(type, idUser, password || "");
			if (serverResponse.data.accessToken) {
				this.setTypeAuthorization(IAuth.AuthTypes.email);
				localStorage.setItem("typeAuthorization", IAuth.AuthTypes.email);
				localStorage.setItem("wallet", idUser);
				localStorage.setItem("accessToken", serverResponse.data.accessToken);
				localStorage.setItem("refreshToken", serverResponse.data.refreshToken);
				this.setTimerToRefreshToken(serverResponse.data.accessToken);
				await this.setProfileData();
				this.setAuth(true);
				return true;
			} else {
				return serverResponse.data;
			}
		} catch (e) {
			console.log(e);
			return false;
		} finally {
			this.setLoading(false);
		}
	};

	loginMetaMask = async (
		type: IAuth.AuthTypes,
		idUser: string,
		password?: string
	) => {
		try {
			let typeAuth;
			typeAuth = type;

			const account = await getEthereumAccount(
				Number(window.ethereum.networkVersion)
			);
			if (!account) {
				return;
			}
			const resNonce = await fetchGetNonce(
				IAuth.AuthTypes.evm,
				idUser.toLocaleLowerCase()
			);
			const signedData: string = await account.signer.signMessage(
				String(resNonce.data.nonce)
			);
			const serverResponse = await fetchAuthLoginMetaMask(
				typeAuth,
				idUser.toLocaleLowerCase(),
				signedData
			);

			this.setTypeAuthorization(IAuth.AuthTypes.evm);
			console.log("LOGINNNNNNNNN    accountId", idUser, type, password);

			console.log(
				"login serverResponse.data.refreshToken",
				serverResponse.data
			);
			if (serverResponse.data.accessToken) {
				localStorage.setItem("wallet", idUser);
				localStorage.setItem("accessToken", serverResponse.data.accessToken);
				localStorage.setItem("refreshToken", serverResponse.data.refreshToken);
				localStorage.setItem("typeAuthorization", IAuth.AuthTypes.evm);
				this.setTimerToRefreshToken(serverResponse.data.accessToken);

				await this.setProfileData();
				this.setAuth(true);

				return true;
			} else {
				return serverResponse.data.errorMessage;
			}
		} catch (e) {
			console.log(e);
			return false;
		} finally {
			this.setLoading(false);
		}
	};

	login = async (signedData: ISignedData, address: string) => {
		try {
			const serverResponse = await fetchAuthLoginNeo(signedData);
			console.log("res", serverResponse);
			if (serverResponse.data.accessToken) {
				this.setTypeAuthorization(IAuth.AuthTypes.neo);
				localStorage.setItem("typeAuthorization", IAuth.AuthTypes.neo);
				localStorage.setItem("accessToken", serverResponse.data.accessToken);
				localStorage.setItem("refreshToken", serverResponse.data.refreshToken);
				localStorage.setItem("wallet", address);
				this.setTimerToRefreshToken(serverResponse.data.accessToken);
				this.setAuth(true);
				await this.setProfileData();
				return true;
			} else {
				return serverResponse.data;
			}
		} catch (e) {
			console.log(e);
			return false;
		} finally {
			this.setLoading(false);
		}
	};

	logout = async () => {
		console.log("LOGOUT ______________________");
		this.setLoading(true);
		try {
			//await fetchAuthLogout();
			localStorage.removeItem("accessToken");
			localStorage.removeItem("refreshToken");
			this.setAuth(false);
			this.setUser({
				container: "",
				createdAt: "",
				id: 0,
				memory: "",
				nonce: "",
				username: "",
				email: "",
				email_confirmed: "",
				code: "",
				discord: "",
				password: "",
				additional_memory: ""
			});
			this.setFiles([]);
			this.setTypeAuthorization("");
			localStorage.removeItem("typeAuthorization");
			localStorage.removeItem("wallet");
		} catch (e) {
			console.log(e);
		} finally {
			this.setLoading(false);
		}
	};

	setTimerToRefreshToken = async (accessToken) => {
		const token = jwtDecode(accessToken) as any;
		let expiredMS = token.exp * 1000;

		let milliseconds = Math.floor((expiredMS - Date.now()) / 2);
		console.log(
			"setTimerToRefreshToken expiredDate",
			expiredMS,
			token.exp * 1000,
			milliseconds
		);
		if (milliseconds < 0) {
			await this.refreshTokenF();
			return;
		}

		if (this.timerToRefreshToken) clearTimeout(this.timerToRefreshToken);

		this.timerToRefreshToken = setTimeout(() => {
			this.refreshTokenF();
		}, milliseconds);
	};

	refreshTokenF = async () => {
		console.log("refreshTokenF");
		const refreshToken = localStorage.getItem("refreshToken");
		if (!refreshToken) {
			return;
		}
		const serverResponse = await fetchAuthRefresh(refreshToken);
		console.log("fetchAuthRefresh", serverResponse);
		if (serverResponse.data.errorMessage) {
			console.error(serverResponse.data.errorMessage);
			await this.logout();
			return;
		}
		localStorage.setItem("accessToken", serverResponse.data.accessToken);
		localStorage.setItem("refreshToken", serverResponse.data.refreshToken);
		this.setTimerToRefreshToken(serverResponse.data.accessToken);
		return serverResponse;
	};

	checkAuth = async () => {
		console.log("checkAuth");
		this.setLoading(true);
		try {
			const accessToken = localStorage.getItem("accessToken");
			if (accessToken) {
				const token = jwtDecode(accessToken) as any;
				let expiredMS = Math.floor((token.exp * 1000 - Date.now()) / 2);
				console.log("expiredMS", expiredMS);
				await this.setTimerToRefreshToken(accessToken);
			} else {
				const serverResponse = await this.refreshTokenF();
				if (!serverResponse) {
					return;
				}
				console.log("refresh serverResponse.data", serverResponse.data);
				console.log("refresh fetchAuthRefresh", serverResponse.data);
				console.log("refresh refresh 1", localStorage.getItem("refreshToken"));
			}

			await this.setProfileData();
			this.setAuth(true);
		} catch (error) {
			console.log("error", error);
		} finally {
			console.log("finally");
			this.setLoading(false);
		}
	};
}
