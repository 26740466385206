import { AxiosResponse } from "axios";
import agent from "./axios";
import { IAuth, ISignedData } from "src/types/user";

export const fetchGetNonce = (
	loginType: IAuth.AuthTypes,
	address: string
): Promise<AxiosResponse<any>> => {
	return agent.get(
		`/auth/get_nonce?login_type=${loginType}&address=${address}`
	);
}

export const fetchAuthLogin = (
	loginType: string,
	id: string,
	password?: string
): Promise<AxiosResponse<any>> => {
	return agent.post("/auth/login", {
		login_type: loginType,
		id,
		password,
	});
};

export const fetchAuthLoginNeo = (
	response: ISignedData
): Promise<AxiosResponse<any>> => {
	return agent.post("/auth/login_neo", {
		response: response,
	});
};

export const fetchUpload = (
	file: string,
	name: string
): Promise<AxiosResponse<any>> => {
	return agent.post("/general/upload", { data: { file: file, name: name } });
};

export const fetchCreateContainer = (): Promise<AxiosResponse<any>> => {
	return agent.post("/general/container");
};

export const fetchAuthRefresh = (
	refreshToken: string
): Promise<AxiosResponse<any>> => {
	return agent.post(`/auth/refresh`, {
		refreshToken: refreshToken,
	});
};

export const fetchDelete = (object: string): Promise<AxiosResponse<any>> => {
	return agent.post(`/general/delete`, {
		object: object,
	});
};

export const fetchAuthLogout = (): Promise<AxiosResponse<any>> => {
	return agent.post("/auth/logout");
};

export const fetchGetProfile = (): Promise<AxiosResponse<any>> => {
	return agent.get("/profile/get_profile");
};

export const fetchGetObjects = (): Promise<AxiosResponse<any>> => {
	return agent.get("/general/objects");
};

export const fetchGetApps = (): Promise<AxiosResponse<any>> => {
	return agent.get("/general/apps");
};

export const fetchRegister = (
	registerType: string,
	login: string,
	password: string,
	nickname: string
): Promise<AxiosResponse<any>> => {
	return agent.post(`/auth/register/`, {
		registerType,
		login,
		password,
		nickname,
	});
};

export const fetchSendEmailCode = (
	email: string
): Promise<AxiosResponse<any>> => {
	return agent.post(`/email_code/send`, {
		email,
	});
};

export const fetchCheckEmailCode = (
	email: string,
	code: number
): Promise<AxiosResponse<any>> => {
	return agent.post(`/email_code/check`, {
		email,
		code,
	});
};

export const fetchCheckNickname = (
	nickname: string
): Promise<AxiosResponse<any>> => {
	// console.log("data.walletAddress",accountId)
	return agent.get(`/profile/check_nickname?nickname=${nickname}`);
};

export const fetchAuthLoginMetaMask = (
	loginType: IAuth.AuthTypes,
	address: string,
	signature: string
): Promise<AxiosResponse<IAuth.Tokens | any>> => {
	return agent.post("/auth/login_metamask", {
		login_type: loginType,
		address,
		signature,
	});
};