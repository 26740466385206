import styles from "./Sidebar.module.scss";
import neobox from "../../assets/img/svg/neobox.svg";
import arrow from "../../assets/img/svg/arrow.svg";
import arrowUp from "../../assets/img/svg/arrowUp.svg";
import categories from "../../assets/img/svg/categories.svg";
import createFolder from "../../assets/img/svg/createFolder.svg";
import files from "../../assets/img/svg/files.svg";
import folders from "../../assets/img/svg/folders.svg";
import shared from "../../assets/img/svg/shared.svg";
import neoboxText from "../../assets/img/svg/neoboxText.svg";
import trash from "../../assets/img/svg/trash.svg";
import cl from "classnames";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { observer } from "mobx-react-lite";
import importImage from "../../assets/img/svg/Import.svg";
import gamepad from "../../assets/img/svg/gamepad.svg";
import { useContext } from "react";
import { Context } from "src";
import { ConfirmUpload } from "../ConfirmUpload/ConfirmUpload";
import { modal } from "../Modal/ModalConnector";

export const Sidebar = observer(() => {
	const navigate = useNavigate();
	const intl = useIntl();
	const { storeUser } = useContext(Context);

	const _handleImageChange = async (e) => {
		const files = (e.target as HTMLInputElement).files;
		if (files && files[0]) {
			readImage(files[0]);
			console.log(files[0]);
		}
	};

	function readImage(file: File) {
		readFileDataAsBase64(file).then((currentFile) => {
			console.log("file", currentFile);
			console.log(String(currentFile));
			modal.open(<ConfirmUpload file={file} image={String(currentFile)} />, {
				className: "none",
			});
		});
	}

	const readFileDataAsBase64 = (file: any) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = (event) => {
				resolve(event.target?.result);
			};

			reader.onerror = (err) => {
				reject(err);
			};

			reader.readAsDataURL(file);
		});
	};

	return (
		<div className={styles.content}>
			<div className={styles.logo} onClick={() => navigate("/")}>
				<img src={neobox} alt="" />
				<img src={neoboxText} alt="" />
			</div>
			<div className={styles.rows}>
				<div
					className={cl(styles.uploadBlock, {
						[styles.disabled]:
							Number(storeUser.user.memory) >
							50 * 10 ** 6 + Number(storeUser.user.additional_memory),
						//||
						//storeUser.user.container === null,
					})}
				>
					<img src={importImage} alt="" />
					{intl.formatMessage({ id: "Upload" })}
					<label htmlFor="upload-photo" className={styles.importButton}></label>
					<input
						type="file"
						id="upload-photo"
						onChange={(e) => _handleImageChange(e)}
						style={{ display: "none" }}
					/>
				</div>
				<div className={styles.row} onClick={() => navigate("/allFiles")}>
					<img src={files} alt="" />
					<div>{intl.formatMessage({ id: "All files" })}</div>
				</div>
				<div className={styles.row} onClick={() => navigate("/panel")}>
					<img src={importImage} alt="" />
					<div>{intl.formatMessage({ id: "Panel" })}</div>
				</div>
				<div className={styles.row} onClick={() => navigate("/panelApps")}>
					<img src={gamepad} alt="" />
					<div>{intl.formatMessage({ id: "Apps" })}</div>
					<div className={styles.new}>{intl.formatMessage({ id: "NEW" })}</div>
				</div>
				<div className={styles.row}>
					<img src={categories} alt="" className={styles.disabled} />
					<div className={styles.disabled}>
						{intl.formatMessage({ id: "Categories" })}
					</div>
					<img src={arrow} alt="" className={styles.disabled} />
					<div className={styles.soon}>
						{intl.formatMessage({ id: "coming soon" })}
					</div>
				</div>
				<div className={styles.row}>
					<img src={shared} alt="" className={styles.disabled} />
					<div className={styles.disabled}>
						{intl.formatMessage({ id: "Shared" })}
					</div>
					<div className={styles.soon} style={{ right: "50px" }}>
						{intl.formatMessage({ id: "coming soon" })}
					</div>
				</div>
				<div className={styles.row}>
					<img src={trash} alt="" className={styles.disabled} />
					<div className={styles.disabled}>
						{intl.formatMessage({ id: "Deleted files" })}
					</div>
					<div className={styles.soon} style={{ right: "10px" }}>
						{intl.formatMessage({ id: "coming soon" })}
					</div>
				</div>
			</div>
			<div className={styles.line}></div>
			<div className={cl(styles.rows, styles.rowsFolders)}>
				<div className={styles.row}>
					<img src={folders} alt="" className={styles.disabled} />
					<div className={styles.disabled}>
						{intl.formatMessage({ id: "Folders" })}
					</div>
					<img src={arrow} alt="" className={styles.disabled} />
					<div className={styles.soon} style={{ right: "30px" }}>
						{intl.formatMessage({ id: "coming soon" })}
					</div>
				</div>
				{/* <div className={styles.row}>
					<div className={styles.blue}></div>
					<div className={styles.bold}>Work</div>
				</div>
				<div className={styles.row}>
					<div className={styles.purple}></div>
					<div className={styles.bold}>Archive photos</div>
				</div>
				<div className={styles.row}>
					<div className={styles.green}></div>
					<div className={styles.bold}>Other stuff</div>
				</div> */}
				<div className={styles.row}>
					<img src={createFolder} alt="" className={styles.disabled} />
					<div className={styles.disabled}>
						{intl.formatMessage({ id: "Create folder" })}
					</div>
					<div className={styles.soon}>
						{intl.formatMessage({ id: "coming soon" })}
					</div>
				</div>
			</div>
			<div className={cl(styles.block, styles.disabled)}>
				<div className={styles.button}>
					{intl.formatMessage({ id: "Choose Your Tarif Plan" })}
				</div>
			</div>
		</div>
	);
});
