import { useContext } from "react";
import styles from "./ControlPanelTop.module.scss";
import { Context } from "src";
import { useIntl } from "react-intl";
import search from "../../assets/img/svg/search.svg";
import cl from "classnames";
import importImage from "../../assets/img/svg/Import.svg";
import add from "../../assets/img/svg/add.svg";
import folder from "../../assets/img/svg/folder.svg";
import { fetchCreateContainer } from "src/app/api/app";
import { IProfile } from "src/types/user";
import { observer } from "mobx-react-lite";

export const ControlPanelTop = observer(() => {
	const { storeUser } = useContext(Context);
	const intl = useIntl();

	const createContainer = async () => {
		try {
			const res = await fetchCreateContainer();
			if (res.data.container) {
				let user: IProfile = JSON.parse(JSON.stringify(storeUser.user));
				user.container = res.data.container;
				storeUser.setUser(user);
			}
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div className={styles.content}>
			<div className={styles.top}>
				<div className={styles.search}>
					<img src={search} alt="" />
					<div>{intl.formatMessage({ id: "Search" })}</div>
				</div>
				{localStorage.getItem("wallet") && (
					<div className={styles.button}>
						{localStorage.getItem("wallet")!.length > 7
							? localStorage.getItem("wallet")!.slice(0, 7) + "..."
							: localStorage.getItem("wallet")}
					</div>
				)}
			</div>
			<div className={styles.blocks}>
				<div
					className={cl(styles.block, styles.disabled, {
						[styles.disabled]: storeUser.user.container,
					})}
					onClick={() => createContainer()}
				>
					<img src={add} alt="" />
					<div>{intl.formatMessage({ id: "Create" })}</div>
				</div>
				<div
					className={cl(styles.block, {
						[styles.disabled]:
							Number(storeUser.user.memory) >
							50 * 10 ** 6 + Number(storeUser.user.additional_memory),
						//||
						//storeUser.user.container === null,
					})}
				>
					<img src={importImage} alt="" height={"24px"} />
					<div>{intl.formatMessage({ id: "Upload" })}</div>
					<label htmlFor="upload-photo"></label>
				</div>
				<div className={cl(styles.block, styles.disabled)}>
					<img src={folder} alt="" />
					<div>{intl.formatMessage({ id: "Create folder" })}</div>
				</div>
			</div>
		</div>
	);
});
