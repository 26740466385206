import styles from "./MainPage.module.scss";
import book from "../../assets/img/png/book.png";
import caseImage from "../../assets/img/png/case.png";
import diagram from "../../assets/img/png/diagram.png";
import diagramPurple from "../../assets/img/png/diagramPurple.png";
import key from "../../assets/img/png/key.png";
import keyYellow from "../../assets/img/png/keyYellow.png";
import laptop from "../../assets/img/png/laptop.png";
import laptopOrange from "../../assets/img/png/laptopOrange.png";
import lightblub from "../../assets/img/png/lightblub.png";
import lightblubBlue from "../../assets/img/png/lightblubBlue.png";
import lock from "../../assets/img/png/lock.png";
import phone from "../../assets/img/png/phone.png";
import settings from "../../assets/img/png/settings.png";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "src";
import fire from "../../assets/img/svg/fire.svg";
import crystal from "../../assets/img/svg/crystal.svg";
import parachute from "../../assets/img/svg/parachute.svg";
import cl from "classnames";
import logo from "../../assets/img/svg/neobox.svg";
import deQuest from "../../assets/img/png/deQuest.png";
import unity from "../../assets/img/png/unity.png";
import ScrollAnimation from "react-animate-on-scroll";
import flamingo from "../../assets/img/png/flamingo.png";
import okx from "../../assets/img/png/okx.png";
import bingx from "../../assets/img/png/bingx.png";
import bitget from "../../assets/img/png/bitget.svg";
import kucoin from "../../assets/img/png/kucoin.png";
import gateio from "../../assets/img/png/gateio.png";
import binance from "../../assets/img/png/binance.png";
import UNCW from "../../assets/img/png/UNCW.jpeg";
import mami from "../../assets/img/svg/mami.svg";
import Roadmap from "../../components/Roadmap/Roadmap";
import fileIcon from "src/assets/img/svg/fileIcon.svg";
import fireIcon from "src/assets/img/svg/fireIcon.svg";

export const MainPage = observer(() => {
	const navigate = useNavigate();
	const intl = useIntl();
	const { storeUser } = useContext(Context);
	const neobox = useRef<any>(null);

	const containerIcons = useRef<HTMLDivElement>(null);
	const [randValues, setRandValues] = useState<number[] | null>(null);
	const [isOpenNeo, setIsOpenNeo] = useState<boolean>(false);
	const [isOpenGas, setIsOpenGas] = useState<boolean>(false);

	function getRandomElements(arr, count) {
		let shuffled = arr.slice(0),
			i = arr.length,
			min = i - count,
			temp,
			index;
		while (i-- > min) {
			index = Math.floor((i + 1) * Math.random());
			temp = shuffled[index];
			shuffled[index] = shuffled[i];
			shuffled[i] = temp;
		}
		return shuffled.slice(min);
	}

	useEffect(() => {
		let arr: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12];
		setRandValues(getRandomElements(arr, 6));

		setTimeout(() => {
			let arr: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12];
			setRandValues(getRandomElements(arr, 6));
		}, 1000);

		setInterval(() => {
			let arr: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12];
			setRandValues(getRandomElements(arr, 4));
		}, 3000);
	}, []);

	useEffect(() => {
		if (containerIcons.current && randValues) {
			randValues.forEach((value) => {
				//@ts-ignore
				containerIcons.current.childNodes[value].style.transform = "scale(0.8)";
				setTimeout(() => {
					try {
						//@ts-ignore
						containerIcons.current.childNodes[value].style.transform =
							"scale(1.2)";
					} catch (e) {}
				}, 3000);
				setTimeout(() => {
					try {
						//@ts-ignore
						containerIcons.current.childNodes[value].style.transform =
							"scale(1)";
					} catch (e) {}
				}, 6000);
			});
		}
	}, [randValues]);

	const arrow = (
		<svg
			width="20"
			height="13"
			viewBox="0 0 20 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 2.58138L10 13L20 2.58138L17.5223 0L10 7.83724L2.47766 0L0 2.58138Z"
				fill="#7CF8C4"
			/>
		</svg>
	);

	return (
		<div className={cl(styles.wrapper, styles.wrapperMainPage)}>
			<div className={styles.content}>
				<div className={styles.title}>
					{intl
						.formatMessage({
							id: "Explore possibilities of PIX10-based cloud storage",
						})
						.replace("PIX10", "NEO")}
				</div>
				<div className={styles.icons}>
					<div className={styles.text}>
						{intl.formatMessage({ id: "Keep your files safe with NEOBOX." })}{" "}
					</div>
					<div
						className={styles.buttonTry}
						onClick={() => navigate("/welcome")}
					>
						{intl.formatMessage({ id: "Try for Free" })}
					</div>
					<div className={styles.containerIcons} ref={containerIcons}>
						<img src={book} alt="" className={styles.book} />
						<img src={diagramPurple} alt="" className={styles.diagramPurple} />
						<img src={caseImage} alt="" className={styles.caseImage} />
						<img src={diagram} alt="" className={styles.diagram} />
						<img src={keyYellow} alt="" className={styles.key} />
						<img src={key} alt="" className={styles.keyYellow} />
						<img src={laptop} alt="" className={styles.laptop} />
						<img src={laptopOrange} alt="" className={styles.laptopOrange} />
						<img src={lightblub} alt="" className={styles.lightblub} />
						<img src={lightblubBlue} alt="" className={styles.lightblubBlue} />
						<img src={lock} alt="" className={styles.lock} />
						<img src={phone} alt="" className={styles.phone} />
						<img src={settings} alt="" className={styles.settings} />
					</div>
				</div>
				<div className={cl(styles.subTitleProgram, styles.subTitleBenefits)}>
					{intl.formatMessage({ id: "A safe place for all your files" })}
				</div>
				<div className={styles.description}>
					{intl.formatMessage({
						id: "Built on NeoFS",
					})}
				</div>
				<div className={styles.neoItems}>
					<ScrollAnimation
						delay={500}
						initiallyVisible={true}
						animateIn={styles.animateNeoItem}
					>
						<div className={cl(styles.neoItem, styles.neoItemFirst)}>
							<div>
								{intl.formatMessage({ id: "Supports all types of files" })}
							</div>
							<img src={fileIcon} alt="" />
						</div>
					</ScrollAnimation>
					<ScrollAnimation
						delay={500}
						initiallyVisible={true}
						animateIn={styles.animateNeoItemSecond}
					>
						<div className={cl(styles.neoItem, styles.neoItemSecond)}>
							<div>{intl.formatMessage({ id: "Safe storage" })}</div>
							<img src={crystal} alt="" />
						</div>
					</ScrollAnimation>
					<div className={cl(styles.neoItem, styles.neoItemThird)}>
						<div>{intl.formatMessage({ id: "Cost efficient" })}</div>
						<img src={fireIcon} alt="" />
					</div>
				</div>
				<div
					className={styles.freeDemoButton}
					onClick={() =>
						storeUser.isAuth ? navigate("/panel") : navigate("/welcome")
					}
				>
					{intl.formatMessage({ id: "Free Demo" })}
				</div>
				<div className={styles.neoboxBlock} ref={neobox}>
					<div className={cl(styles.subTitleProgram, styles.subTitleNeobox)}>
						{intl
							.formatMessage({ id: "What is PIX10?" })
							.replace("PIX10", "NEOBOX")}
					</div>
					<div className={styles.neoboxContent}>
						<div>
							<div className={styles.neoboxDescription}>
								<div>
									<img src={logo} alt="" />
									<span>NEOBOX</span>{" "}
									{intl.formatMessage({
										id: "is a revolutionary service akin to Google Drive or Dropbox, but built on NeoFS technology of the ",
									})}
									<span
										onClick={() => window.open("https://neo.org")}
										style={{
											cursor: "pointer",
											zIndex: "2",
											position: "relative",
										}}
									>
										Neo blockchain.
									</span>
								</div>
							</div>
							<div className={styles.neoboxSubDescription}>
								{intl.formatMessage({
									id: "With NEOBOX, you can securely upload and store your data in a decentralized manner, ensuring greater privacy and control over your information. Plus, enjoy more cost-effective storage fees compared to traditional services, all while keeping your data out of the hands of centralized organizations.",
								})}
							</div>
						</div>
						<ScrollAnimation animateIn={styles.animateEllipseBlock}>
							<div className={styles.ellipseBlock}>
								<svg
									onClick={() => window.open("https://fs.neo.org/")}
									width="172"
									height="172"
									viewBox="0 0 172 172"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<circle cx="86" cy="86" r="85.5" stroke="white" />
									<path
										d="M106.742 50.1424L108.734 49.9668L108.558 47.9745L106.566 48.1501L106.742 50.1424ZM70.924 89.7713C70.2146 90.6179 70.3258 91.8793 71.1724 92.5888C72.019 93.2982 73.2804 93.187 73.9899 92.3404L70.924 89.7713ZM112.048 87.5658L108.734 49.9668L104.749 50.318L108.064 87.917L112.048 87.5658ZM106.566 48.1501L68.9669 51.4645L69.3181 55.4491L106.917 52.1346L106.566 48.1501ZM105.209 48.8578L70.924 89.7713L73.9899 92.3404L108.274 51.4269L105.209 48.8578Z"
										fill="#7CF8C4"
									/>
								</svg>
								<div className={styles.textInEllipseBlock}>
									{intl.formatMessage({ id: "Learn about NeoFS" })}
								</div>
							</div>
						</ScrollAnimation>
					</div>
				</div>
				<div className={cl(styles.subTitleProgram, styles.subTitleIntegrated)}>
					{intl.formatMessage({ id: "Integrated with" })}
				</div>
				<div className={styles.partners}>
					<div className={styles.partnerBlock}>
						<div className={styles.partner}>
							<ScrollAnimation animateIn={styles.animatePartner}>
								<img src={deQuest} alt="" height={"102px"} />
							</ScrollAnimation>
						</div>
						<div className={styles.partner}>
							<ScrollAnimation animateIn={styles.animatePartner}>
								<img src={unity} alt="" height={"75px"} />
							</ScrollAnimation>
						</div>
						<div className={styles.partner}>
							<ScrollAnimation animateIn={styles.animatePartner}>
								TBA
							</ScrollAnimation>
						</div>
					</div>
					<div className={styles.partnerBlock}>
						<div className={styles.partner}>
							<ScrollAnimation animateIn={styles.animatePartner}>
								<img src={UNCW} alt="" height={"75px"} />
							</ScrollAnimation>
						</div>
						<div className={styles.partner}>
							<ScrollAnimation animateIn={styles.animatePartner}>
								<img src={mami} alt="" height={"75px"} />
							</ScrollAnimation>
						</div>
					</div>
				</div>
				<Roadmap />
				<div className={styles.whereBlocks}>
					<div className={styles.whereBlockClose}>
						<div>{intl.formatMessage({ id: "Where to buy NEO / bNEO" })}</div>
						{isOpenNeo && (
							<div className={styles.clickBlocks}>
								<div
									onClick={() =>
										window.open("https://flamingo.finance/on-ramp")
									}
								>
									<img src={flamingo} alt="" className={styles.flamingo} />
								</div>
								<div className={styles.clickBlocksContent}>
									<div
										onClick={() =>
											window.open(
												"https://www.binance.com/en/trade/NEO_USDT?ref=40896146&type=spot"
											)
										}
									>
										<img src={binance} alt="" className={styles.binance} />
									</div>
									<div
										onClick={() =>
											window.open(
												"https://www.kucoin.com/trade/NEO-USDT?rcode=rPH7VCS"
											)
										}
									>
										<img src={kucoin} alt="" className={styles.kucoin} />
									</div>
									<div
										onClick={() =>
											window.open(
												"https://www.okx.com/trade-spot/neo-usdt?channelid=43776722"
											)
										}
									>
										<img src={okx} alt="" className={styles.okx} />
									</div>
									<div
										onClick={() =>
											window.open("https://www.bitget.com/spot/NEOUSDT")
										}
									>
										<img src={bitget} alt="" className={styles.bitget} />
									</div>
									<div
										onClick={() =>
											window.open("https://www.gate.io/trade/NEO_USDT")
										}
									>
										<img src={gateio} alt="" className={styles.gateio} />
									</div>
									<div
										onClick={() =>
											window.open("https://bingx.com/en-us/spot/NEOUSDT")
										}
									>
										<img src={bingx} alt="" className={styles.bingx} />
									</div>
								</div>
							</div>
						)}
						<div
							className={styles.click}
							onClick={() => setIsOpenNeo(!isOpenNeo)}
						>
							<div className={cl(styles.arrow, { [styles.active]: isOpenNeo })}>
								{arrow}
							</div>
							<div style={{ display: isOpenNeo ? "none" : "block" }}>
								{intl.formatMessage({ id: "Click to show" })}
							</div>
						</div>
					</div>
					<div className={styles.whereBlockClose}>
						<div>{intl.formatMessage({ id: "Where to buy GAS" })}</div>
						{isOpenGas && (
							<div className={styles.clickBlocks}>
								<div
									onClick={() =>
										window.open("https://flamingo.finance/on-ramp")
									}
								>
									<img src={flamingo} alt="" className={styles.flamingo} />
								</div>
								<div className={styles.clickBlocksContent}>
									<div
										onClick={() =>
											window.open(
												"https://www.binance.com/en/trade/GAS_USDT?ref=40896146"
											)
										}
									>
										<img src={binance} alt="" className={styles.binance} />
									</div>
									<div
										onClick={() =>
											window.open(
												"https://www.kucoin.com/trade/GAS-USDT?rcode=rPH7VCS"
											)
										}
									>
										<img src={kucoin} alt="" className={styles.kucoin} />
									</div>
									<div
										onClick={() =>
											window.open(
												"https://www.okx.com/trade-spot/gas-usdt?channelid=43776722"
											)
										}
									>
										<img src={okx} alt="" className={styles.okx} />
									</div>
									<div
										onClick={() =>
											window.open(
												"https://www.bitget.com/spot/GASUSDT?type=spot"
											)
										}
									>
										<img src={bitget} alt="" className={styles.bitget} />
									</div>
									<div
										onClick={() =>
											window.open("https://www.gate.io/trade/GAS_USDT")
										}
									>
										<img src={gateio} alt="" className={styles.gateio} />
									</div>
									<div
										onClick={() =>
											window.open("https://bingx.com/en-us/spot/GASUSDT")
										}
									>
										<img src={bingx} alt="" className={styles.bingx} />
									</div>
								</div>
							</div>
						)}
						<div
							className={styles.click}
							onClick={() => setIsOpenGas(!isOpenGas)}
						>
							<div className={cl(styles.arrow, { [styles.active]: isOpenGas })}>
								{arrow}
							</div>
							<div style={{ display: isOpenGas ? "none" : "block" }}>
								{intl.formatMessage({ id: "Click to show" })}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
