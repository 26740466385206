import { useContext, useEffect } from "react";
import styles from "./AllFiles.module.scss";
import { Context } from "src";
import { Sidebar } from "src/components/Sidebar/Sidebar";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import arrow from "../../assets/img/svg/arrow.svg";
import { ControlPanelTop } from "src/components/ControlPanelTop/ControlPanelTop";
import unlock from "../../assets/img/svg/unlock.svg";
import { FileItem } from "src/components/FileItem/FileItem";

export const AllFiles = observer(() => {
	const { storeUser } = useContext(Context);
	const intl = useIntl();

	return (
		<div className={styles.wrapperPanel}>
			<Sidebar />
			<div className={styles.content}>
				<ControlPanelTop />
				<div className={styles.workareaTop}>
					<div className={styles.allFiles}>
						<div>{intl.formatMessage({ id: "All files" })}</div>
						<img src={arrow} alt="" />
					</div>
					<div className={styles.unlock}>
						<img src={unlock} alt="" />
						<div>{intl.formatMessage({ id: "Everyone can see" })}</div>
					</div>
				</div>
				<div className={styles.files}>
					{storeUser.files
						.slice()
						.sort(function (a, b) {
							let findA = storeUser.objects.find(
								(i) =>
									i.address.containerId === a.container &&
									i.address.objectId === a.object
							)?.name;
							let findB = storeUser.objects.find(
								(i) =>
									i.address.containerId === b.container &&
									i.address.objectId === b.object
							)?.name;
							if (findA && findB && findA > findB) {
								return 1;
							}
							if (findA && findB && findA < findB) {
								return -1;
							}
							return 0;
						})
						.map((item, index) => {
							return <FileItem item={item} />;
						})}
				</div>
			</div>
		</div>
	);
});
