import styles from "./Footer.module.scss";
import neobox from "../../assets/img/svg/neobox.svg";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
	const intl = useIntl();
	const navigate = useNavigate();

	return (
		<div className={styles.content}>
			<div className={styles.mainContent}>
				<div className={styles.logo} onClick={() => navigate("/")}>
					<img src={neobox} alt="" />
					<div>neobox</div>
				</div>
				<div className={styles.text}>
					{intl.formatMessage({ id: "Privacy Policy" })}
				</div>
				<div className={styles.text}>
					{intl.formatMessage({ id: "Terms of Use" })}
				</div>
				<div
					className={styles.text}
					onClick={() => window.open("https://fs.neo.org")}
					style={{ cursor: "pointer", zIndex: "2" }}
				>
					{intl.formatMessage({ id: "Powered by NeoFS" })}
				</div>
			</div>
		</div>
	);
};
