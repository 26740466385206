import { useContext, useEffect, useState } from "react";
import styles from "./Apps.module.scss";
import { Sidebar } from "src/components/Sidebar/Sidebar";
import { useIntl } from "react-intl";
import { Context } from "src";
import cl from "classnames";
import search from "../../assets/img/svg/search.svg";
import arrow from "../../assets/img/svg/arrow.svg";
import comigSoon from "../../assets/img/svg/comigSoon.svg";
import { AppItem } from "src/components/AppItem/AppItem";
import { observer } from "mobx-react-lite";

export const Apps = observer(() => {
	const intl = useIntl();
	const { storeUser } = useContext(Context);
	const [isShow, setIsShow] = useState<boolean>(false);

	useEffect(() => {
		storeUser.getApps();
	}, []);

	return (
		<div className={styles.wrapperPanel}>
			<Sidebar />
			<div className={styles.content}>
				<div className={styles.top}>
					<div className={styles.search}>
						<img src={search} alt="" />
						<div>{intl.formatMessage({ id: "Search" })}</div>
					</div>
					{localStorage.getItem("wallet") && (
						<div className={styles.button}>
							{localStorage.getItem("wallet")!.length > 7
								? localStorage.getItem("wallet")!.slice(0, 7) + "..."
								: localStorage.getItem("wallet")}
						</div>
					)}
				</div>
				<div className={styles.top}>
					<div className={styles.files}>
						<div>{intl.formatMessage({ id: "Apps" })}</div>
						<img src={arrow} alt="" />
					</div>
					<div
						onMouseEnter={() => setIsShow(true)}
						onMouseLeave={() => setIsShow(false)}
						style={{ color: "#7cf8c4", cursor: "pointer" }}
					>
						{intl.formatMessage({ id: "Become a Contributor" })}
					</div>
					{isShow && (
						<div className={styles.publish}>
							<div>
								{intl.formatMessage({
									id: "Publish your app on NEOBOX and take part in airdrop for contributors",
								})}
							</div>
						</div>
					)}
				</div>
				<div className={styles.items}>
					{storeUser.apps.map((item, index) => {
						return <AppItem app={item} />;
					})}
					{Array(3)
						.fill(0)
						.map((item, index) => {
							return (
								<div className={styles.item}>
									<img src={comigSoon} alt="" />
									<div className={styles.mainContent}>
										<div>{intl.formatMessage({ id: "Coming Soon" })}</div>
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
});
