import { FC, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";

const PrivateRoute: FC<any> = ({ children }) => {
	const navigate = useNavigate();
	const { storeUser } = useContext(Context);
	console.log("PrivateRoute", storeUser.isAuth, storeUser.isLoading);

	useEffect(() => {
		if (
			(!storeUser.isAuth && !storeUser.isLoading) ||
			!localStorage.getItem("accessToken")
		) {
			navigate("/");
		}
	}, [storeUser.isLoading]);

	return children;
};

export default observer(PrivateRoute);
