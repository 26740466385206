import React, { useEffect, useState } from "react";
import EventEmitter from "events";
import Modal from "./Modal";

const ev = new EventEmitter();
export interface IModalParams {
	className?: string;
	classNameAdd?: string;
	abilityHide?: boolean;
	id?: string;
	componentProps?: React.ComponentProps<any>;
	tutorial?: boolean;
}

export const modal = {
	open: (component: React.ReactNode, props?: IModalParams) => {
		ev.emit("open", { component, props });
	},
	close: () => {
		ev.emit("close");
	},
};

export const ModalConnector = () => {
	const [active, setActive] = useState(false);
	const [content, setContent] = useState<React.ReactNode>(null);
	const [props, setProps] = useState<IModalParams>({});
	let time;
	useEffect(() => {
		const openModal = ({
			component,
			props,
		}: {
			component: React.ReactNode;
			props: IModalParams;
		}) => {
			setContent(component);
			setProps(props);
			setActive(true);
			clearTimeout(time);
		};

		const closeModal = () => {
			setActive(false);
			clearTimeout(time);
			console.log("!!!!!!");
			time = setTimeout(() => {
				setContent(null);
				console.log("12312312");
			}, 500);
		};

		ev.on("open", openModal);
		ev.on("close", closeModal);

		return () => {
			ev.off("open", openModal);
			ev.off("close", closeModal);
		};
	}, []);

	return (
		<Modal active={active} setActive={setActive} {...props}>
			{content}
		</Modal>
	);
};
